<template>
  <div class="container">
    <el-card class="box-card">
      <el-button type="primary" size="small" @click="teacherShow = true">添加海报</el-button>
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id"></el-table-column>
        <el-table-column align="center" label="海报名称" prop="name"></el-table-column>
        <el-table-column align="center" label="海报图片" prop="id">
          <template slot-scope="scope">
            <el-image :previewSrcList="[scope.row.materialImg]" style="width: 100px;" :src="scope.row.materialImg"
                      fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="排序值" prop="sortNo"></el-table-column>
        <el-table-column align="center" label="是否默认">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isTemplate == 0 ? 'info' : 'success'">{{ scope.row.isTemplate == 0 ? '否' : '是' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="id">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'danger' : 'success'">{{ scope.row.status == 0 ? '停用' : '启用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="id">
          <template slot-scope="scope">
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row.id)" icon="el-icon-delete" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <el-dialog :title="form.id ? '编辑海报' : '添加海报'" :visible.sync="teacherShow" width="40%" :before-close="handleClose">
      <el-form :model="form" ref="form" label-width="120px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="海报名称:" prop="name">
          <el-input @focus="clearValidate('name')" v-model="form.name" placeholder="请输入课程名称"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="课程封面图:" label-width="120px" prop="materialImg">
          <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                     :on-success="successImages">
            <img v-if="form.materialImg" :src="form.materialImg" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序值:" prop="sortNo">
          <el-input @focus="clearValidate('sortNo')" v-model="form.sortNo" placeholder="请输入课程名称"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否系统默认:">
          <el-switch v-model="systemDefault" active-text="是" inactive-text="否"></el-switch>
        </el-form-item>
        <el-form-item label="状态:">
          <el-switch v-model="statusOpen" active-text="开启" inactive-text="关闭"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {coursePosterTemplatePage, coursePosterTemplateAddonset, coursePosterTemplateDel} from '@/apis/poster'
import {poster} from "@/utils/rules";

export default {
  data() {
    return {
      query: {
        current: 1,
        size: 10,
      },
      tableData: [],
      loading: false,
      total: null,
      teacherShow: false, // 添加对话框
      rules: poster.rules,
      form: {
        name: "", // 海报模板名称
        materialImg: "", // 海报素材图片
        sortNo: "", // 	排序值
        isTemplate: "", // 	是否系统默认模板图片(0=否 1=是)
        status: "", // 	状态(0=停用 1=启用)
      },
      systemDefault: false,
      statusOpen: true
    };
  },
  created() {
    this.getData()
  },
  methods: {
    submit() {
      this.$refs.form.validate(async (val) => {
        if (!val) return;
        const {systemDefault, statusOpen} = this;
        this.form.isTemplate = systemDefault ? 1 : 0;
        this.form.status = statusOpen ? 1 : 0;
        await coursePosterTemplateAddonset(this.form);
        this.$message({
          message: this.form.id ? "编辑成功" : "添加成功",
          type: "success",
        });
        this.teacherShow = false;
        this.getData();
        this.clearCen();
      });
    },
    // 编辑线上课程
    async handlerEdit(item) {
      this.teacherShow = true;
      const {isTemplate, status} = item;
      this.form = {...item};
      this.systemDefault = isTemplate == 1 ? true : false;
      this.statusOpen = status == 1 ? true : false;
    },
    // 删除课程类型
    handlerRemove(id) {
      this.$confirm("此操作将永久删除课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            await coursePosterTemplateDel({id});
            this.query.current = 1;
            this.$message({message: "删除成功", type: "success"});
            this.getData();
          })
          .catch((err) => {
          });
    },
    handleClose() {
      this.teacherShow = false;
      this.clearCen();
    },
    handleSizeChange(size) {
      this.query.size = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.query.current = page
      this.getData()
    },
    async getData() {
      this.loading = true
      const res = await coursePosterTemplatePage(this.query)
      const {records, total, current, size} = res.data
      this.tableData = records
      this.total = total
      this.query.current = current
      this.query.size = size
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.isAuditionOpen = true;
      this.statusOpen = true;
      delete this.form.id; // 删除对象里面的id
    },
    // 上传图片成功的钩子函数
    successImages(response, file, fileList) {
      this.form.materialImg = response.data;
    },
    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  width: 70%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 160px;
  border: 1px dashed #999;
}

.avatar {
  width: 140px;
  height: 160px;
  display: block;
}
</style>