// 海报管理
import axios from 'axios'
import QS from 'qs'
// 分页
export const coursePosterTemplatePage = params => {
    return axios.post(`/api/coursePosterTemplate/page`, QS.stringify(params)).then(res => res.data)
}
// 编辑 / 修改
export const coursePosterTemplateAddonset = params => {
    return axios.post(`/api/coursePosterTemplate/addOnSet`, QS.stringify(params)).then(res => res.data)
}
// 删除
export const coursePosterTemplateDel = params => {
    return axios.post(`/api/coursePosterTemplate/del`, QS.stringify(params)).then(res => res.data)
}